<template>
	<main>
		<h2>About</h2>
	</main>
</template>

<script>
export default {
	name: 'About',
}
</script>
